// src/components/FormCreator.js
import React, { useState } from 'react';
import FormField from './FormField';

function FormCreator({ formFields, setFormFields }) {
  const [fieldType, setFieldType] = useState('text');

  const addField = () => {
    setFormFields([...formFields, { type: fieldType, label: `Field ${formFields.length + 1}` }]);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Add Fields to Form</h2>
      <div className="flex space-x-4 items-center">
        <select
          onChange={(e) => setFieldType(e.target.value)}
          value={fieldType}
          className="border border-gray-300 rounded-md p-2"
        >
          <option value="text">Text</option>
          <option value="checkbox">Checkbox</option>
        </select>
        <button
          onClick={addField}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Add Field
        </button>
      </div>
      <div className="mt-6 space-y-4">
        {formFields.map((field, index) => (
          <FormField key={index} field={field} />
        ))}
      </div>
    </div>
  );
}

export default FormCreator;
