// src/pages/ReviewFormPage.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormReview from '../components/FormReview';

function ReviewFormPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { formFields } = location.state || { formFields: [] };

  const handleEdit = () => {
    navigate('/');
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md p-8 mt-10 rounded-md">
      <h1 className="text-2xl font-bold mb-6">Review Your Form</h1>
      <FormReview formFields={formFields} />
      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleEdit}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          Edit Form
        </button>
        <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">
          Submit Form
        </button>
      </div>
    </div>
  );
}

export default ReviewFormPage;
