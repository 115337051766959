// src/pages/CreateFormPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormCreator from '../components/FormCreator';

function CreateFormPage() {
    const [formFields, setFormFields] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/review', { state: { formFields } });
    };

    return (
        <div className="max-w-4xl mx-auto bg-white shadow-md p-8 mt-10 rounded-md">
            <h1 className="text-2xl font-bold mb-6">Create Your Form</h1>
            <FormCreator formFields={formFields} setFormFields={setFormFields} />
            <div className="mt-6">
                <button
                    onClick={handleSubmit}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    Review Form
                </button>
            </div>
        </div>
    );
}

export default CreateFormPage;
