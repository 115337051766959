// src/components/FormReview.js
import React from 'react';

function FormReview({ formFields }) {
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">Your Form Preview</h2>
      <form className="space-y-4">
        {formFields.map((field, index) => (
          <div key={index} className="space-y-2">
            <label className="block text-sm font-medium">{field.label}</label>
            {field.type === 'text' && (
              <input
                type="text"
                className="border border-gray-300 rounded-md w-full p-2"
                readOnly
              />
            )}
            {field.type === 'checkbox' && (
              <input type="checkbox" className="h-5 w-5" readOnly />
            )}
          </div>
        ))}
      </form>
    </div>
  );
}

export default FormReview;
