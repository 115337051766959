// App.js
import React, { useState } from "react";

const ListFormPage = () => {
    // State untuk menyimpan list survey/template
    const [surveys, setSurveys] = useState([
        "Survey 1",
        "Survey 2",
        "Survey 3",
    ]);

    // Fungsi untuk handle tombol create survey baru
    const handleCreateSurvey = () => {
        const newSurvey = `Survey ${surveys.length + 1}`;
        setSurveys([...surveys, newSurvey]);
    };

    return (
        <div className="flex flex-row justify-start items-start min-h-screen bg-gray-100 font-sans">
            {/* Sidebar Create */}
            <div className="w-1/4 p-4">
                <h1 className="text-2xl font-bold mb-6 text-center">Create Survey</h1>
                <button
                    className="bg-green-500 w-full text-white py-2 px-4 rounded hover:bg-green-600 mb-4"
                    onClick={handleCreateSurvey}
                >
                    Create New Survey
                </button>
            </div>

            {/* List Template Survey */}
            <div className="w-3/4 p-4 flex flex-col">
                <h2 className="text-xl font-semibold mb-4">List of Surveys</h2>
                <div className="flex-grow overflow-y-auto">
                    <div className="grid grid-cols-2 gap-4">
                        {surveys.map((survey, index) => (
                            <div
                                key={index}
                                className="p-4 bg-white shadow-md rounded-md border border-gray-200 hover:shadow-lg transition-shadow duration-300"
                            >
                                <h3 className="text-lg font-medium"> {survey} </h3>
                                <p className="text-gray-600">Created on: 2024-10-02</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListFormPage;
