// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateFormPage from './pages/CreateFormPage';
import ListFormPage from './pages/ListFormPage';
import ReviewFormPage from './pages/ReviewFormPage';

function App() {
  return (
    <Router className="min-h-screen bg-gray-100">
      <Routes>
        <Route path="/" element={<ListFormPage />} />
        <Route path="/create" element={<CreateFormPage />} />
        <Route path="/review" element={<ReviewFormPage />} />
      </Routes>
    </Router>
  );
}

export default App;
